import React, { useState, useEffect, useRef } from "react";
import {
  getCampaigns,
  createCampaign,
  scheduleCampaign,
  cancelCampaign,
  deleteCampaign,
  publishCampaign,
} from "../services/api";
import { useParams } from "react-router-dom";

const CampaignDashboard = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [view, setView] = useState("details");
  const { id } = useParams();
  const [selectedDate, setSelectedDate] = useState(null);
  const [intervalHours, setIntervalHours] = useState("");
  const [loading, setLoading] = useState(false);
  const datePickerRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);

  useEffect(() => {
    getCampaigns()
      .then((response) => setCampaigns(response.data))
      .catch((error) => console.error("Error al obtener las campañas:", error));
  }, []);

  const handleSelectCampaign = (campaign) => {
    setSelectedCampaign(campaign);
    setView("details");
  };

  const handleCreateCampaign = () => {
    setSelectedCampaign(null);
    setView("create");
  };

  const handleClickOpen = (id) => {
    console.log("Campaign ID:", id);
    setSelectedCampaignId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedCampaignId(null);
  };

  const handleDelete = () => {
    deleteCampaign(selectedCampaignId)
      .then(() => {
        setCampaigns(
          campaigns.filter((campaign) => campaign.id !== selectedCampaignId)
        );
        handleClose();
      })
      .catch((error) => {
        console.error("Error al eliminar la campaña:", error);
      });
  };

  const handleSubmitSchCampaign = async (e) => {
    e.preventDefault();

    if (!selectedDate) {
      setMessage("You have not selected a date.");
      setSeverity("error");
      setOpen(true);
      return;
    }

    setLoading(true);
    try {
      const campaignId = id;
      const scheduledTime = selectedDate ? selectedDate.toISOString() : null;
      const interval = intervalHours ? parseInt(intervalHours, 10) : null;

      const response = await scheduleCampaign(
        campaignId,
        scheduledTime,
        interval
      );
      console.log("Campaña programada:", response);
      setMessage("Scheduled Task.");
      setSeverity("success");
      setOpen(true);
    } catch (error) {
      console.error("Error programando la campaña:", error);
      setMessage("There was a problem scheduling the task.");
      setSeverity("error");
      setOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitCreateCampaign = async (e) => {
    e.preventDefault();
    try {
      const campaignData = {
        title: e.target.title.value,
        description: e.target.description.value,
        description_img: e.target.description_img.value,
        image_url: e.target.image_url.value,
      };

      const response = await createCampaign(campaignData);
      setCampaigns([...campaigns, response.data]);
      setView("details");
      console.log("Campaña creada:", response.data);
    } catch (error) {
      console.error("Error al crear la campaña:", error);
    }
  };

  const renderRightPanel = () => {
    if (view === "create") {
      return (
        <div>
          <h2 className="text-2xl font-semibold mb-4">Create New Campaign</h2>
          <form onSubmit={handleSubmitCreateCampaign} className="space-y-4">
            <div>
              <label className="block text-gray-700">Campaign Name</label>
              <input
                name="title"
                type="text"
                className="w-full px-4 py-2 border rounded-md"
                placeholder="Enter campaign name"
                required
              />
            </div>
            <div>
              <label className="block text-gray-700">Campaign Text</label>
              <textarea
                name="description"
                className="w-full px-4 py-2 border rounded-md"
                rows="4"
                placeholder="Enter campaign description"
              />
            </div>
            <div>
              <label className="block text-gray-700">
                Description for Image
              </label>
              <textarea
                name="description_img"
                className="w-full px-4 py-2 border rounded-md"
                rows="4"
                placeholder="Enter image description"
                required
              />
            </div>
            <div>
              <label className="block text-gray-700">Image URL</label>
              <input
                name="image_url"
                type="text"
                className="w-full px-4 py-2 border rounded-md"
                placeholder="Enter image URL"
              />
            </div>
            <div className="flex gap-4 mt-4">
              <button
                type="submit"
                className="w-full py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
              >
                Create Campaign
              </button>
            </div>
          </form>
        </div>
      );
    }

    if (!selectedCampaign) {
      return (
        <p className="text-gray-500">Select a campaign to view details.</p>
      );
    }

    switch (view) {
      case "details":
        return (
          <div>
            <h2 className="text-2xl font-semibold mb-4">
              {selectedCampaign.title}
            </h2>
            <p className="text-gray-700 mb-4">{selectedCampaign.description}</p>
            <img
              src={selectedCampaign.image_url}
              alt={selectedCampaign.title}
              className="rounded-md w-full mb-4"
            />
            <div className="flex space-x-4">
              <button
                title="Edit"
                onClick={() => setView("edit")}
                className="text-blue-500 hover:text-blue-600 flex items-center"
              >
                <EditIcon /> Edit Campaign
              </button>
              <button
                title="Schedule"
                onClick={() => setView("schedule")}
                className="text-blue-500 hover:text-blue-600 flex items-center"
              >
                <ScheduleIcon /> Schedule Campaign
              </button>
              <button
                title="Delete"
                className="text-red-500 hover:text-red-600 flex items-center"
                onClick={() => handleClickOpen(id)}
              >
                <DeleteIcon /> Delete Campaign
              </button>
            </div>
          </div>
        );
      case "edit":
        return (
          <div>
            <h2 className="text-2xl font-semibold mb-4">Edit Campaign</h2>
            <form className="space-y-4">
              <div>
                <label className="block text-gray-700">Campaign Name</label>
                <input
                  type="text"
                  className="w-full px-4 py-2 border rounded-md"
                  defaultValue={selectedCampaign.title}
                />
              </div>
              <div>
                <label className="block text-gray-700">Campaign Text</label>
                <textarea
                  className="w-full px-4 py-2 border rounded-md"
                  rows="4"
                  defaultValue={selectedCampaign.description}
                />
              </div>
              <div>
                <label className="block text-gray-700">
                  Description for Image
                </label>
                <textarea
                  className="w-full px-4 py-2 border rounded-md"
                  rows="4"
                  defaultValue={selectedCampaign.description_img}
                />
              </div>
              <div>
                <label className="block text-gray-700">Image URL</label>
                <input
                  type="text"
                  className="w-full px-4 py-2 border rounded-md"
                  defaultValue={selectedCampaign.image_url}
                />
              </div>
              <div className="flex gap-4 mt-4">
                <button
                  type="submit"
                  className="w-full py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                >
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        );
      case "schedule":
        return (
          <div>
            <h2 className="text-2xl font-semibold mb-4">Schedule Campaign</h2>
            <form onSubmit={handleSubmitSchCampaign} className="space-y-4">
              <div>
                <label className="block text-gray-700">
                  Select Date and Time
                </label>
                <input
                  onChange={(date) => setSelectedDate(date)}
                  type="datetime-local"
                  className="w-full px-4 py-2 border rounded-md"
                  ref={datePickerRef}
                />
              </div>
              <div>
                <label className="block text-gray-700">
                  Interval (hours, Optional)
                </label>
                <input
                  type="number"
                  min={1}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                  value={intervalHours}
                  onChange={(e) => setIntervalHours(e.target.value)}
                  placeholder="Enter interval in hours (optional)"
                />
              </div>
              <div className="flex gap-4 mt-4">
                <button
                  type="submit"
                  className={`w-full py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600 ${
                    loading ? "cursor-not-allowed opacity-50" : ""
                  }`}
                  disabled={loading}
                >
                  {loading ? "Programming..." : "Schedule Campaign"}
                </button>
                <button
                  type="button"
                  className="w-full py-2 px-4 bg-red-500 text-white rounded hover:bg-red-600"
                  onClick={async () => {
                    try {
                      await cancelCampaign(id);
                      setMessage("Campaign canceled.");
                      setSeverity("success");
                      setOpen(true);
                    } catch (error) {
                      setMessage("There was a problem canceling the campaign.");
                      setSeverity("error");
                      setOpen(true);
                    }
                  }}
                >
                  Cancel Campaign
                </button>
              </div>
            </form>
            {open && (
              <div
                className={`mt-4 p-4 rounded ${
                  severity === "success"
                    ? "bg-green-100 text-green-700"
                    : "bg-red-100 text-red-700"
                }`}
              >
                {message}
              </div>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex h-screen bg-gray-50">
      {/* Panel Izquierdo */}
      <div className="w-3/5 bg-white p-6 border-r border-gray-200 shadow-lg">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-lg font-semibold text-gray-700">Campaigns</h2>
          <button
            onClick={handleCreateCampaign}
            className="text-blue-500 hover:text-blue-600 flex items-center"
          >
            <PlusIcon /> New Campaign
          </button>
        </div>
        <div className="overflow-y-auto h-80">
          <table className="w-full text-left">
            <thead>
              <tr className="text-gray-500 border-b">
                <th className="px-4 py-2">Name</th>
                <th className="px-4 py-2">Status</th>
                <th className="px-4 py-2 text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {campaigns.map((campaign) => (
                <tr key={campaign.id} className="hover:bg-gray-100">
                  <td
                    className="px-4 py-2 cursor-pointer"
                    onClick={() => handleSelectCampaign(campaign)}
                  >
                    {campaign.title}
                  </td>
                  <td>
                    <td>
                      <span
                        className={`px-2 py-1 rounded ${
                          campaign.is_canceled === true
                            ? "bg-green-100 text-green-800"
                            : campaign.is_canceled === false
                            ? "bg-red-100 text-red-800"
                            : "bg-yellow-100 text-yellow-800"
                        }`}
                      >
                        {campaign.is_canceled === true
                          ? "Published"
                          : campaign.is_canceled === false
                          ? "Canceled"
                          : "Pending"}
                      </span>
                    </td>
                  </td>
                  <td className="px-4 py-2 flex space-x-2">
                    <button
                      title="Edit"
                      onClick={() => {
                        setSelectedCampaign(campaign);
                        setView("edit");
                      }}
                      className="text-blue-500 hover:text-blue-600"
                    >
                      <EditIcon />
                    </button>
                    <button
                      title="Schedule"
                      onClick={() => {
                        setSelectedCampaign(campaign);
                        setView("schedule");
                      }}
                      className="text-blue-500 hover:text-blue-600"
                    >
                      <ScheduleIcon />
                    </button>
                    <button
                      title="Publish"
                      onClick={async () => {
                        try {
                          await publishCampaign(campaign.id); // Llama a la API para publicar
                          alert("Campaign published successfully.");
                        } catch (error) {
                          console.error("Error publishing campaign:", error);
                          alert("Failed to publish campaign.");
                        }
                      }}
                      className="text-green-500 hover:text-green-600"
                    >
                      <PublishIcon />
                    </button>
                    <button
                      title="Delete"
                      onClick={() => handleClickOpen(campaign.id)}
                      className="text-red-500 hover:text-red-600"
                    >
                      <DeleteIcon />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {open && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white p-6 rounded shadow-lg max-w-sm">
                <h3 className="text-lg font-bold mb-4">Confirm Deletion</h3>
                <p className="text-gray-600 mb-4">
                  Are you sure you want to delete this campaign? This action
                  cannot be undone.
                </p>
                <div className="flex justify-end">
                  <button
                    onClick={handleClose}
                    className="px-4 py-2 mr-2 bg-gray-300 rounded hover:bg-gray-400"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleDelete}
                    className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Panel Derecho */}
      <div className="w-3/5 bg-white p-8 overflow-auto">
        {renderRightPanel()}
      </div>
    </div>
  );
};

export default CampaignDashboard;

// Componentes de Íconos SVG Personalizados

const EditIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5 mr-2"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M11 5h2v2h-2zM12 20v-8h8M7.5 11.5l7-7m-5.5 5L3 20h4l4-4"
    />
  </svg>
);

const ScheduleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5 mr-2"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 8v4l2 2m-6-7h3m0 5h1m4-4h1M7 7H4v14h16V7h-3m-6 5h1M7 15h10"
    />
  </svg>
);

const DeleteIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5 mr-2"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
);

const PlusIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5 mr-2"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 4v16m8-8H4"
    />
  </svg>
);

const PublishIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    fill="currentColor"
    {...props}
  >
    <path d="M12 2L2 12h3v8h6v-6h2v6h6v-8h3L12 2z" />
  </svg>
);
