import React from "react";
import { Link } from "react-router-dom";
import { Home, Briefcase, LogOut } from "lucide-react";

function Navbar() {
  return (
    <div className="h-screen w-64 bg-white text-gray-800 flex flex-col items-start py-7 border-r border-gray-300">
      <h1 className="text-3xl font-bold text-center w-full mb-10">Marketing App</h1>
      <div className="space-y-3 w-full px-4">
        <Link to="/dashboard" className="flex items-center px-4 py-2 text-gray-600 rounded hover:bg-gray-100 hover:text-gray-800">
          <Home className="w-6 h-6 mr-2" /> Dashboard
        </Link>
        <Link to="/leads" className="flex items-center px-4 py-2 text-gray-600 rounded hover:bg-gray-100 hover:text-gray-800">
          <Briefcase className="w-6 h-6 mr-2" /> Leads
        </Link>
        <Link to="/campaigns" className="flex items-center px-4 py-2 text-gray-600 rounded hover:bg-gray-100 hover:text-gray-800">
          <Briefcase className="w-6 h-6 mr-2" /> Campaigns
        </Link>
        <Link to="/settings" className="flex items-center px-4 py-2 text-gray-600 rounded hover:bg-gray-100 hover:text-gray-800">
          <Briefcase className="w-6 h-6 mr-2" /> Settings
        </Link>
        <Link to="/" className="flex items-center px-4 py-2 text-gray-600 rounded hover:bg-gray-100 hover:text-gray-800">
          <LogOut className="w-6 h-6 mr-2" /> Logout
        </Link>
      </div>
    </div>
  );
}

export default Navbar;
