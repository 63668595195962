import React, { useEffect, useState } from "react";
import { getLeads } from "../services/api";
import { useNavigate } from "react-router-dom";

function LeadList() {
  const [leads, setLeads] = useState([]);
  const [selectedLead, setSelectedLead] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getLeads()
      .then((response) => {
        setLeads(response.data);
      })
      .catch((error) => {
        console.error("Error al obtener los leads:", error);
      });
  }, []);

  const handleSelectLead = (lead) => {
    setSelectedLead(lead);
  };

  const handleDeleteLead = (id) => {
    // Aquí puedes implementar la funcionalidad de eliminación de leads
    console.log(`Eliminar lead con ID: ${id}`);
  };

  const renderRightPanel = () => {
    if (!selectedLead) {
      return <p className="text-gray-500">Select a lead to view details.</p>;
    }

    return (
      <div>
        <h2 className="text-2xl font-semibold mb-4">{selectedLead.name}</h2>
        <div className="space-y-4">
          <p>
            <strong>Email:</strong>{" "}
            <a
              href={`mailto:${selectedLead.email}`}
              className="text-blue-500 hover:underline flex items-center"
            >
              <EmailIcon /> {selectedLead.email}
            </a>
          </p>
          <p>
            <strong>Phone:</strong>{" "}
            <a
              href={`tel:${selectedLead.phone_number}`}
              className="text-blue-500 hover:underline flex items-center"
            >
              <PhoneIcon /> {selectedLead.phone_number}
            </a>
          </p>
          <p>
            <strong>Current Coverage:</strong> {selectedLead.current_coverage}
          </p>
          <p>
            <strong>Dependents:</strong> {selectedLead.dependents}
          </p>
          <p>
            <strong>Health Status:</strong> {selectedLead.health_status}
          </p>
          <p>
            <strong>Income:</strong> {selectedLead.income}
          </p>
          <p>
            <strong>Interested:</strong> {selectedLead.interested_in}
          </p>
          <p>
            <strong>State:</strong> {selectedLead.state}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="flex h-screen bg-gray-50">
      {/* Panel Izquierdo */}
      <div className="w-2/5 bg-white p-6 border-r border-gray-200 shadow-lg">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-lg font-semibold text-gray-700">Leads</h2>
          <button
            onClick={() => navigate("/leads-create")}
            className="text-blue-500 hover:text-blue-600 flex items-center"
          >
            <AddIcon /> Add Lead
          </button>
        </div>
        <div className="overflow-y-auto h-80">
          <table className="w-full text-left">
            <thead>
              <tr className="text-gray-500 border-b">
                <th className="px-4 py-2">Name</th>
                <th className="px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {leads.map((lead) => (
                <tr key={lead.id} className="hover:bg-gray-100">
                  <td className="px-4 py-2">{lead.name}</td>
                  <td className="px-4 py-2 flex space-x-4">
                    <button
                      title="View Details"
                      onClick={() => handleSelectLead(lead)}
                      className="text-blue-500 hover:text-blue-600 flex items-center"
                    >
                      <DetailIcon />
                    </button>
                    <button
                      title="Delete Lead"
                      onClick={() => handleDeleteLead(lead.id)}
                      className="text-red-500 hover:text-red-600 flex items-center"
                    >
                      <DeleteIcon />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Panel Derecho */}
      <div className="w-3/5 bg-white p-8 overflow-auto">
        {renderRightPanel()}
      </div>
    </div>
  );
}

export default LeadList;

// Componentes de Íconos SVG

const EmailIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5 mr-2"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3 8l8.5 5.5a2 2 0 002 0L21 8m-18 0v8a2 2 0 002 2h14a2 2 0 002-2V8m-18 0l8.5-5.5a2 2 0 012.5 0L21 8"
    />
  </svg>
);

const PhoneIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5 mr-2"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3 10a16 16 0 0016 16h0M3 21v-3m5-1l2-2"
    />
  </svg>
);

const AddIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5 mr-2"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 4v16m8-8H4"
    />
  </svg>
);

const DetailIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5 mr-2"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M10 4a6 6 0 100 12 6 6 0 000-12zM21 21l-4.35-4.35"
    />
  </svg>
);

const DeleteIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5 mr-2"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
);
