import React, { useEffect, useState } from "react";
import { getDashboardData } from "../services/api";
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";

function Dashboard() {
  const [data, setData] = useState(null);

  useEffect(() => {
    getDashboardData()
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error al cargar datos del dashboard", error);
      });
  }, []);

  // Datos de ejemplo (reemplazar con data del servidor)
  const leadSourceData = [
    { name: "Facebook", value: 46 },
    { name: "Instagram", value: 31 },
    { name: "WhatsApp", value: 15 },
    { name: "Direct", value: 8 },
  ];

  const weeklyLeadData = [
    { day: "Mon", Facebook: 4, Instagram: 3, WhatsApp: 2 },
    { day: "Tue", Facebook: 3, Instagram: 2, WhatsApp: 1 },
    { day: "Wed", Facebook: 6, Instagram: 5, WhatsApp: 3 },
    { day: "Thu", Facebook: 5, Instagram: 4, WhatsApp: 2 },
    { day: "Fri", Facebook: 8, Instagram: 7, WhatsApp: 5 },
    { day: "Sat", Facebook: 4, Instagram: 3, WhatsApp: 2 },
    { day: "Sun", Facebook: 3, Instagram: 2, WhatsApp: 1 },
  ];

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  return (
    <div className="container mx-auto mt-5 p-4">
      <h2 className="text-3xl font-semibold mb-6">Dashboard</h2>
      {data ? (
        <div>
          {/* Tarjetas de resumen */}
          <div className="grid grid-cols-4 gap-4 mb-6">
            <div className="bg-white p-4 rounded-lg shadow-md text-center">
              <p className="text-gray-500">Total Leads</p>
              <p className="text-2xl font-semibold">{data.totalLeads || 247}</p>
              <p className="text-green-500 text-sm">↑ 12% from last month</p>
            </div>
            <div className="bg-white p-4 rounded-lg shadow-md text-center">
              <p className="text-gray-500">Active Chats</p>
              <p className="text-2xl font-semibold">{data.activeChats || 18}</p>
              <p className="text-green-500 text-sm">↑ 8% from last week</p>
            </div>
            <div className="bg-white p-4 rounded-lg shadow-md text-center">
              <p className="text-gray-500">Posts Created</p>
              <p className="text-2xl font-semibold">{data.postsCreated || 156}</p>
              <p className="text-green-500 text-sm">↑ 24% from last month</p>
            </div>
            <div className="bg-white p-4 rounded-lg shadow-md text-center">
              <p className="text-gray-500">Conversion Rate</p>
              <p className="text-2xl font-semibold">{data.conversionRate || "8.7%"}</p>
              <p className="text-green-500 text-sm">↑ 3% from last month</p>
            </div>
          </div>

          {/* Gráficos */}
          <div className="grid grid-cols-2 gap-4">
            {/* Gráfico de Distribución de Fuentes de Leads */}
            <div className="bg-white p-4 rounded-lg shadow-md">
              <h3 className="text-lg font-semibold mb-4">Lead Sources Distribution</h3>
              <PieChart width={300} height={300}>
                <Pie
                  data={leadSourceData}
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  fill="#8884d8"
                  dataKey="value"
                  label
                >
                  {leadSourceData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </div>

            {/* Gráfico de Generación Semanal de Leads */}
            <div className="bg-white p-4 rounded-lg shadow-md">
              <h3 className="text-lg font-semibold mb-4">Weekly Lead Generation by Source</h3>
              <BarChart width={500} height={300} data={weeklyLeadData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="day" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="Facebook" fill="#0088FE" />
                <Bar dataKey="Instagram" fill="#FF8042" />
                <Bar dataKey="WhatsApp" fill="#00C49F" />
              </BarChart>
            </div>
          </div>
        </div>
      ) : (
        <p>Cargando datos...</p>
      )}
    </div>
  );
}

export default Dashboard;
