import React, { useState, useEffect } from "react";
import {getSettings, updateSetting, createSetting} from "../services/api";

const SettingsDashboard = () => {
  const [settings, setSettings] = useState([]);
  const [selectedSetting, setSelectedSetting] = useState(null);
  const [view, setView] = useState("details");

  useEffect(() => {
    getSettings()
      .then((response) => {
        console.log(response.data); // Inspecciona qué se devuelve
        setSettings(response.data);
      })
      .catch((error) => console.error("Error al cargar configuraciones:", error));
  }, []);
  

  const handleAddSetting = () => {
    const newSetting = {
      openai_key: "",
      facebook_key: "",
      id_instagran: "",
      id_facebook: "",
      url_system: "",
      url_leads: "",
      app_facebook_id: "",
      app_facebook_secret: "",
    };
    setSelectedSetting(newSetting);
    setView("edit");
  };

  const handleSaveSetting = async (e) => {
    e.preventDefault();
  
    try {
      if (selectedSetting.id) {
        // Actualizar configuración existente
        await updateSetting(selectedSetting.id, selectedSetting);
        alert("Settings updated successfully!");
      } else {
        // Crear una nueva configuración
        const response = await createSetting(selectedSetting);
        setSettings([...settings, response.data]);
        setSelectedSetting(response.data);
        setView("details");
        alert("Settings added successfully!");
      }
    } catch (error) {
      console.error("Error saving settings:", error);
      alert("Failed to save settings.");
    }
  };

  const handleChange = (e) => {
    setSelectedSetting({ ...selectedSetting, [e.target.name]: e.target.value });
  };

  const renderRightPanel = () => {
    if (!selectedSetting) {
      return <p className="text-gray-500">Select a setting to view details.</p>;
    }

    if (view === "details") {
      return (
        <div>
          <h2 className="text-2xl font-semibold mb-4">Setting Details</h2>
          <p>
            <strong>OpenAI Key:</strong> {selectedSetting.openai_key}
          </p>
          <p>
            <strong>Facebook Key:</strong> {selectedSetting.facebook_key}
          </p>
          <p>
            <strong>Instagram User ID:</strong> {selectedSetting.id_instagran}
          </p>
          <p>
            <strong>Facebook Page ID:</strong> {selectedSetting.id_facebook}
          </p>
          <p>
            <strong>URL System:</strong> {selectedSetting.url_system}
          </p>
          <p>
            <strong>Leads URL:</strong> {selectedSetting.url_leads}
          </p>
          <p>
            <strong>App Facebook ID:</strong> {selectedSetting.app_facebook_id}
          </p>
          <p>
            <strong>App Facebook Secret:</strong> {selectedSetting.app_facebook_secret}
          </p>
          <button
            className="mt-4 py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600"
            onClick={() => setView("edit")}
          >
            Edit Settings
          </button>
        </div>
      );
    }

    if (view === "edit") {
      return (
        <div>
          <h2 className="text-2xl font-semibold mb-4">
            {selectedSetting.id ? "Edit Setting" : "Add New Setting"}
          </h2>
          <form onSubmit={handleSaveSetting} className="space-y-4">
            <div>
              <label className="block text-gray-700">OpenAI Key</label>
              <input
                name="openai_key"
                type="text"
                value={selectedSetting.openai_key}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-md"
              />
            </div>
            <div>
              <label className="block text-gray-700">Facebook Key</label>
              <input
                name="facebook_key"
                type="text"
                value={selectedSetting.facebook_key}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-md"
              />
            </div>
            <div>
              <label className="block text-gray-700">Instagram User ID</label>
              <input
                name="id_instagran"
                type="text"
                value={selectedSetting.id_instagran}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-md"
              />
            </div>
            <div>
              <label className="block text-gray-700">Facebook Page ID</label>
              <input
                name="id_facebook"
                type="text"
                value={selectedSetting.id_facebook}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-md"
              />
            </div>
            <div>
              <label className="block text-gray-700">URL System</label>
              <input
                name="url_system"
                type="text"
                value={selectedSetting.url_system}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-md"
              />
            </div>
            <div>
              <label className="block text-gray-700">Leads URL</label>
              <input
                name="url_leads"
                type="text"
                value={selectedSetting.url_leads}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-md"
              />
            </div>
            <div>
              <label className="block text-gray-700">App ID</label>
              <input
                name="app_facebook_id"
                type="text"
                value={selectedSetting.app_facebook_id}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-md"
              />
            </div>
            <div>
              <label className="block text-gray-700">App Secret</label>
              <input
                name="app_facebook_secret"
                type="text"
                value={selectedSetting.app_facebook_secret}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-md"
              />
            </div>
            <button
              type="submit"
              className="mt-4 py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              {selectedSetting.id ? "Save Changes" : "Add Setting"}
            </button>
          </form>
        </div>
      );
    }
  };

  return (
    <div className="flex h-screen bg-gray-50">
      {/* Panel Izquierdo */}
      <div className="w-2/5 bg-white p-6 border-r border-gray-200 shadow-lg">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Settings</h2>
          {settings.length === 0 && (
          <button
            onClick={handleAddSetting}
            className="text-blue-500 hover:text-blue-600 flex items-center"
          >
            <PlusIcon /> New Settings
          </button>
          )}
        </div>
        <ul>
          {Array.isArray(settings) && settings.map((setting) => (
            <li
              key={setting.id}
              className={`px-4 py-2 cursor-pointer hover:bg-gray-100 ${
                selectedSetting?.id === setting.id ? "bg-gray-200" : ""
              }`}
              
            >
              <span onClick={() => setSelectedSetting(setting)}>Changes to setting</span>
            </li>
          ))}
        </ul>
      </div>

      {/* Panel Derecho */}
      <div className="w-3/5 bg-white p-8 overflow-auto">{renderRightPanel()}</div>
    </div>
  );
};

export default SettingsDashboard;

const PlusIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5 mr-2"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 4v16m8-8H4"
    />
  </svg>
);

