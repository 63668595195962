import React, { useState } from "react";
import { createLead } from "../services/api";
import {
  TextField,
  Button,
  Container,
  Typography,
  Snackbar,
  Alert,
  Paper,
  Avatar,
  Divider,
  Link,
  Box,
} from "@mui/material";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";

function LeadCapture() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success"); // 'success' or 'error'

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const leadData = { name, email, phone_number: phoneNumber };
      await createLead(leadData);

      // Show success Snackbar
      setMessage("Lead captured successfully!");
      setSeverity("success");
      setOpen(true);

      // Clear form fields
      setName("");
      setEmail("");
      setPhoneNumber("");
    } catch (error) {
      console.error("Error capturing lead:", error);

      // Show error Snackbar
      setMessage("Error capturing lead.");
      setSeverity("error");
      setOpen(true);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ textAlign: "center", mt: 5 }}>
      <Paper
        elevation={5}
        sx={{ p: 4, borderRadius: 3, backgroundColor: "#f5f5f5" }}
      >
        <Avatar sx={{ bgcolor: "#4caf50", width: 56, height: 56, mx: "auto" }}>
          <HealthAndSafetyIcon fontSize="large" />
        </Avatar>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ mt: 2, color: "#4caf50", fontWeight: "bold" }}
        >
          Secure Your Future Today!
        </Typography>
        <Typography variant="subtitle1" sx={{ color: "#666", mb: 2 }}>
          Get a Free Insurance Quote in Seconds!
        </Typography>
        <Divider sx={{ mb: 3 }} />

        <form onSubmit={handleSubmit}>
          <TextField
            label="Full Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Phone Number"
            variant="outlined"
            fullWidth
            margin="normal"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <Button
            type="submit"
            variant="contained"
            color="success"
            fullWidth
            sx={{
              mt: 3,
              p: 1.5,
              fontSize: "1rem",
              fontWeight: "bold",
              borderRadius: 2,
              backgroundColor: "#4caf50",
              ":hover": { backgroundColor: "#388e3c" },
            }}
          >
            Get Your Free Quote
          </Button>
        </form>
        {/* Privacy Notice */}
        <Box sx={{ mt: 3 }}>
          <Typography variant="body2" sx={{ color: "#666" }}>
            We value your privacy. Please review our{" "}
            <Link
              href="https://www.privacypolicies.com/live/4bd3d20d-9675-466b-95dc-924216583023"
              target="_blank"
              rel="noopener"
            >
              Privacy Policy
            </Link>{" "}
            to learn more about how we handle your data.
          </Typography>
        </Box>
      </Paper>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default LeadCapture;
