import axios from "axios";

//const API_URL = "http://127.0.0.1:8000/api"; // Ajusta esta URL a tu API
const API_URL = 'https://marketing.sysagencysphere.com/api';

// Configurar axios para incluir el token de autenticación en cada solicitud
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Función de cierre de sesión
export const logout = () => {
  localStorage.removeItem("token"); // Elimina el token del almacenamiento local
};

// Nueva función para programar la campaña
export const scheduleCampaign = (campaignId, scheduledTime, intervalHours) => {
  return axios.post(`${API_URL}/schedule_campaign/`, {
    campaignId,
    scheduledTime,
    intervalHours,
  });
};

export const cancelCampaign = async (campaignId) => {
  const response = await axios.post(
    `${API_URL}/cancel_campaign/${campaignId}/`
  );
  return response.data;
};

export const login = (credentials) => {
  return axios.post(`${API_URL}/users/login/`, credentials);
};

export const createLead = (leadData) => {
  console.log("Sending lead data:", leadData);
  return axios.post(`${API_URL}/leads/`, leadData);
};

export const getLeads = () => {
  return axios.get(`${API_URL}/leads/`);
};

export const createCampaign = (campaignData) => {
  return axios.post(`${API_URL}/campaigns/`, campaignData);
};

export const getCampaigns = () => {
  return axios.get(`${API_URL}/campaigns/`);
};

export const deleteCampaign = (id) => {
  return axios.delete(`${API_URL}/campaigns/${id}/`);
};

export const publishCampaign = async (campaignId) => {
  const response = await axios.post(`${API_URL}/publish_campaign/${campaignId}/`);
  return response.data;
};


export const getDashboardData = () => {
  return axios.get(`${API_URL}/dashboard/`);
};

export const getSettings = () => {
  return axios.get(`${API_URL}/configuracion/items/`);
};

export const updateSetting = (id, settingData) => {
  return axios.put(`${API_URL}/configuracion/items/${id}/`, settingData);
};

export const createSetting = (settingData) => {
  return axios.post(`${API_URL}/configuracion/items/`, settingData);
};



