// App.js
import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Login from "./components/Login";
import LeadList from "./components/LeadList";
import LeadCapture from "./components/LeadCapture";
import CampaignDashboard from "./components/CampaignDashboard";
import Dashboard from "./components/Dashboard";
import SettingsDashboard from "./components/SettingsDashboard";
import HealthChatbot from "./components/Chatbot";
import Navbar from "./components/Navbar";
import { AuthProvider, useAuth } from "./components/AuthContext";

// Componente de Ruta Protegida
const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? children : <Navigate to="/" replace />;
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Ruta pública para el inicio de sesión */}
          <Route path="/" element={<Login />} />
          <Route path="/leads-create" element={<LeadCapture />} />
          <Route path="/chatbot" element={<HealthChatbot />} />

          {/* Rutas protegidas que requieren autenticación */}
          <Route
            path="/*"
            element={
              <ProtectedRoute>
                <div className="flex h-screen">
                  <Navbar />
                  <div className="flex-1 overflow-auto">
                    <Routes>
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route path="/leads" element={<LeadList />} />
                      <Route path="/settings" element={<SettingsDashboard />} />
                      <Route path="/campaigns" element={<CampaignDashboard />} />
                      <Route path="*" element={<Navigate to="/dashboard" replace />} />
                    </Routes>
                  </div>
                </div>
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
