import React, { useState } from "react";
import { createLead } from "../services/api";
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Snackbar,
  Alert,
  Avatar,
  Divider,
  Link,
} from "@mui/material";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";

function HealthChatbot() {
  const [step, setStep] = useState(0); // Initial step
  const [leadData, setLeadData] = useState({
    name: "",
    email: "",
    phone_number: "",
    interested_in: "Obamacare",
    income: "",
    dependents: "",
    state: "",
    health_status: "No",
    current_coverage: "No",
  });
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleNextStep = (data) => {
    setLeadData((prevData) => ({ ...prevData, ...data }));
    setStep(step + 1);
  };

  const handleSubmit = async () => {
    try {
      await createLead(leadData);
      setAlert({
        open: true,
        message: "Information submitted successfully!",
        severity: "success",
      });
      setStep(0); // Return to menu after submission
    } catch (error) {
      if (error.response && error.response.data.email) {
        setAlert({
          open: true,
          message: "Email already exists. Please use a different email.",
          severity: "error",
        });
      } else {
        console.error(
          "Error submitting data",
          error.response ? error.response.data : error
        );
        setAlert({
          open: true,
          message: "Error submitting information",
          severity: "error",
        });
      }
    }
  };

  const handleCloseAlert = () => {
    setAlert({ ...alert, open: false });
  };

  return (
    <Box
      sx={{
        maxWidth: 600,
        margin: "auto",
        padding: 3,
        textAlign: "center",
      }}
    >
      <Paper
        elevation={5}
        sx={{ padding: 4, borderRadius: 3, backgroundColor: "#f5f5f5" }}
      >
        <Avatar
          sx={{ bgcolor: "#4caf50", width: 56, height: 56, margin: "auto" }}
        >
          <HealthAndSafetyIcon fontSize="large" />
        </Avatar>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ mt: 2, color: "#4caf50", fontWeight: "bold" }}
        >
          Welcome to the Obamacare Assistance Chatbot
        </Typography>
        <Divider sx={{ mb: 3 }} />
          {/* Step-by-Step Questions */}
          {step === 0 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Typography variant="h6">What is your full name?</Typography>
              <TextField
                fullWidth
                margin="normal"
                variant="outlined"
                placeholder="Your full name"
                value={leadData.name}
                onChange={(e) =>
                  setLeadData({ ...leadData, name: e.target.value })
                }
              />
              <Button
                variant="contained"
                color="success"
                onClick={() => handleNextStep({ name: leadData.name })}
                sx={{ mt: 2, fontWeight: "bold" }}
              >
                Next
              </Button>
            </Box>
          )}

          {step === 1 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Typography variant="h6">What is your email address?</Typography>
              <TextField
                fullWidth
                margin="normal"
                variant="outlined"
                placeholder="Your email address"
                value={leadData.email}
                onChange={(e) =>
                  setLeadData({ ...leadData, email: e.target.value })
                }
              />
              <Button
                variant="contained"
                color="success"
                onClick={() => handleNextStep({ email: leadData.email })}
                sx={{ mt: 2, fontWeight: "bold" }}
              >
                Next
              </Button>
            </Box>
          )}

          {step === 2 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Typography variant="h6">What is your phone number?</Typography>
              <TextField
                fullWidth
                margin="normal"
                variant="outlined"
                placeholder="Your phone number"
                value={leadData.phone_number}
                onChange={(e) =>
                  setLeadData({ ...leadData, phone_number: e.target.value })
                }
              />
              <Button
                variant="contained"
                color="success"
                onClick={() =>
                  handleNextStep({ phone_number: leadData.phone_number })
                }
                sx={{ mt: 2, fontWeight: "bold" }}
              >
                Next
              </Button>
            </Box>
          )}

          {step === 3 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Typography variant="h6">
                What is your approximate annual income?
              </Typography>
              <TextField
                fullWidth
                margin="normal"
                variant="outlined"
                placeholder="For example, 40000"
                value={leadData.income}
                onChange={(e) =>
                  setLeadData({ ...leadData, income: e.target.value })
                }
              />
              <Button
                variant="contained"
                color="success"
                onClick={() => handleNextStep({ income: leadData.income })}
                sx={{ mt: 2, fontWeight: "bold" }}
              >
                Next
              </Button>
            </Box>
          )}

          {step === 4 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Typography variant="h6">
                How many people depend on you financially?
              </Typography>
              <TextField
                fullWidth
                margin="normal"
                variant="outlined"
                placeholder="Number of dependents"
                value={leadData.dependents}
                onChange={(e) =>
                  setLeadData({ ...leadData, dependents: e.target.value })
                }
              />
              <Button
                variant="contained"
                color="success"
                onClick={() =>
                  handleNextStep({ dependents: leadData.dependents })
                }
                sx={{ mt: 2, fontWeight: "bold" }}
              >
                Next
              </Button>
            </Box>
          )}

          {step === 5 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Typography variant="h6">
                Which state do you reside in?
              </Typography>
              <TextField
                fullWidth
                margin="normal"
                variant="outlined"
                placeholder="For example, California"
                value={leadData.state}
                onChange={(e) =>
                  setLeadData({ ...leadData, state: e.target.value })
                }
              />
              <Button
                variant="contained"
                color="success"
                onClick={() => handleNextStep({ state: leadData.state })}
                sx={{ mt: 2, fontWeight: "bold" }}
              >
                Next
              </Button>
            </Box>
          )}

          {step === 6 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Typography variant="h6">
                Do you have any pre-existing medical conditions?
              </Typography>
              <TextField
                fullWidth
                margin="normal"
                variant="outlined"
                placeholder="Yes or No"
                value={leadData.health_status}
                onChange={(e) =>
                  setLeadData({ ...leadData, health_status: e.target.value })
                }
              />
              <Button
                variant="contained"
                color="success"
                onClick={() =>
                  handleNextStep({ health_status: leadData.health_status })
                }
                sx={{ mt: 2, fontWeight: "bold" }}
              >
                Next
              </Button>
            </Box>
          )}

          {step === 7 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Typography variant="h6">
                Do you currently have health insurance coverage?
              </Typography>
              <TextField
                fullWidth
                margin="normal"
                variant="outlined"
                placeholder="Yes or No"
                value={leadData.current_coverage}
                onChange={(e) =>
                  setLeadData({ ...leadData, current_coverage: e.target.value })
                }
              />
              <Button
                variant="contained"
                color="success"
                onClick={() =>
                  handleNextStep({
                    current_coverage: leadData.current_coverage,
                  })
                }
                sx={{ mt: 2, fontWeight: "bold" }}
              >
                Next
              </Button>
            </Box>
          )}

          {step === 8 && (
            <Button
              variant="contained"
              color="success"
              onClick={handleSubmit}
              sx={{ mt: 2, fontWeight: "bold" }}
            >
              Submit All Information
            </Button>
          )}
        <Box sx={{ mt: 3 }}>
          <Button
            variant="outlined"
            color="success"
            href="https://marketing.sysagencysphere.com/leads-create"
            target="_blank"
            sx={{ mb: 4, fontWeight: "bold" }}
          >
            Quick Registration
          </Button>
        </Box>
        {/* Privacy Notice */}
        <Box sx={{ mt: 3 }}>
          <Typography variant="body2" sx={{ color: "#666" }}>
            We value your privacy. Please review our{" "}
            <Link
              href="https://www.privacypolicies.com/live/4bd3d20d-9675-466b-95dc-924216583023"
              target="_blank"
              rel="noopener"
            >
              Privacy Policy
            </Link>{" "}
            to learn more about how we handle your data.
          </Typography>
        </Box>
      </Paper>

      {/* Snackbar Alert */}
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alert.severity}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default HealthChatbot;
