import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { useAuth } from './AuthContext';
import { login as loginDjango } from '../services/api';

const Login = () => {
    const { login } = useAuth();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleDjangoLogin = async (e) => {
        e.preventDefault();
        setError(null);
        try {
            const response = await loginDjango({ username, password });
            // Guarda el token si es necesario
            localStorage.setItem('token', response.data.token);
            login();  // Actualiza el estado de autenticación
            navigate('/dashboard');  // Redirige al dashboard después del login exitoso
        } catch (err) {
            setError('Credenciales incorrectas');
            console.error('Error en el inicio de sesión:', err);
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-md text-center space-y-6">
                <h2 className="text-3xl font-bold text-blue-600 mb-6">Login</h2>

                {/* Formulario de Login de Django */}
                <form onSubmit={handleDjangoLogin} className="space-y-4">
                    <div>
                        <label className="block text-gray-700 mb-1">Username</label>
                        <input 
                            type="text" 
                            value={username} 
                            onChange={(e) => setUsername(e.target.value)} 
                            required 
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700 mb-1">Password</label>
                        <input 
                            type="password" 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                            required 
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                        />
                    </div>
                    <button 
                        type="submit" 
                        className="w-full py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
                    >
                        Login
                    </button>
                    {error && <p className="text-red-500 mt-4">{error}</p>}
                </form>

                {/* Botón de Login con Facebook */}
                <FacebookLogin
                    appId="1230981404613870"
                    onSuccess={(response) => {
                        console.log('Login Success:', response);
                        login();  // Actualiza el estado de autenticación
                        navigate('/dashboard');  // Redirige al dashboard
                    }}
                    onFail={(error) => {
                        console.error('Login Failed:', error);
                    }}
                    style={{
                        padding: '10px',
                        backgroundColor: '#4267B2',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        width: '100%',
                        fontSize: '16px'
                    }}
                    children="Login with Facebook"
                />
            </div>
        </div>
    );
};

export default Login;
